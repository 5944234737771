#homeFooter {
  min-height: 50px;
  padding: 25px;
  margin: 0;
  font-size: 12px;
  font-weight: 200;

  .footer_right {
    text-align: right;
  }
}

@media only screen and (max-width: 600px) {
  #homeFooter {
    margin-left: 28px;
    ;

    .footer_right {
      text-align: left;

    }
  }
}