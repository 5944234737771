.verticle_center {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(0%, -50%);
    width: 100%;
}

.image-box-container {
    padding: 35px;
}

.img-mobile-view {
    max-height: 280px;
    max-width: 100%;
    margin: 0px auto;
}

@media only screen and (max-width: 600px) {
    .verticle_center {
        position: relative;
        width: 100%;
    }

    .h1 {
        font-size: 2rem;
    }

    .image-box-container {
        padding: 15px;

    }

    .img-mobile-view {
        max-height: 180px;
        max-width: 100%;
        margin: 0px auto;
    }
}

.social {
    color: #ffffff !important;
    margin: 8px 12px 0px 0px;
}