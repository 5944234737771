#resetContainer {
  padding: 0;

  .reset_wrapper {
    margin: 50px auto;
    max-width: 350px;
    min-height: 600px;
    width: 100%;

    .reset_header {
      margin: 25px auto;
      text-align: center;
    }
  }
}
