#verifiedWrapper {
  .verified_container {
    min-height: 600px;
    text-align: center;

    .login_btn {
      display: block;
      width: 300px;
      padding: 10px;
      background-color: #3bb54a;
      font-size: 20px;
      color: white;
      border-radius: 12px;
      margin: 50px auto;
      text-decoration: none;
    }
  }
}
