@import '~font-awesome/css/font-awesome.min.css';
@import 'bootstrap/scss/bootstrap.scss';
@import './_variables';
@import './new';
// @import './_form';
// @import './_banner';
// @import './_header';
// @import './_button';

body {
    margin: 0;
    padding: 0;
    // font-family: sans-serif;
    font-family: 'Ubuntu', sans-serif;
    background-color: $backgroundColor;

    .col-osrit {
        padding-left: 5px;
        padding-right: 5px;
    }
}


@media only screen and (max-width: 600px) {
    body {
        background-color: transparent;

        .bg-light {
            background-color: transparent;
        }
    }
}

 /* for desktop */
      .whatsapp_float {
          position: fixed;
          width: 60px;
          height: 60px;
          bottom: 40px;
          right: 40px;
          background-color: #25d366;
          color: #FFF;
          border-radius: 50px;
          text-align: center;
          font-size: 30px;
          box-shadow: 2px 2px 3px #999;
          z-index: 100;
      }

      .whatsapp-icon {
          margin-top: 16px;
      }

      /* for mobile */
      @media screen and (max-width: 480px)  {  
          .whatsapp-icon {
              margin-top: 8px;
          }

          .whatsapp_float {
              width: 47px;
              height: 47px;
              bottom: 27px;
              right: 17px;
              font-size: 33px;
          }
      }

       @media screen and (min-width: 768px) and (max-width: 1024px) {  
          .whatsapp-icon {
              margin-top: 10px;
          }

          .whatsapp_float {
              width: 55px;
              height: 55px;
              bottom: 30px;
              right: 20px;
              font-size: 37px;
          }
      }

       @media screen and  (min-width: 481px) and (max-width: 767px)  {  
          .whatsapp-icon {
              margin-top: 10px;
          }

          .whatsapp_float {
              width: 51px;
              height: 51px;
              bottom: 36px;
              right: 17px;
              font-size: 35px;
          }
      }