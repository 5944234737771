#registerWrapper {
    .form-register-wrapper {
        border: 0;
        margin: 25px auto;
        max-width: 450px;
        width: 100%;
        border-radius: 5px;
        font-size: 14px;
    }
}
