
#pricingWrapper {
  padding: 0;

  .help_text {
    color: grey;
    font-size: 10px;
  }



  .price_container {
    padding: 15px;
    color: #6c757d;
    font-size: 14px;
    background-color: #8080800a;
    border-radius: 5px;
    min-height: 80px;

    .price_header {
      margin-top: 15px;
      margin-bottom: 25px;
    }


    .price_sign_up_btn {
      background-color: #009ddd;
      max-width: 300px;
      width: 100%;
      margin: auto;
      border: #009ddd;
      padding: 5px 10px;
      margin-top: 10px;
      color: white;
      text-decoration: none;
      display: inline-block;
      border-radius: 5px;

      &:hover {
        background-color: darken(#009ddd, 10%);
      }
    }
  }

  .get_pricing_button {
    background-color: #009ddd;
    margin: auto;
    border: #009ddd;
    padding: 5px 10px;
    margin-top: 10px;
    color: white;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;

    &:hover {
      background-color: darken(#009ddd, 10%);
    }
  }
}

.step {
  padding-top: 1%;
  text-align: center;
  color: rgb(151, 145, 145);
}

.icon_card {
  background-color: white;
  padding: 20px;
  border: 0px;
  box-shadow: #c2c6ca 4px 3px 10px;
  text-align: center;
  font-size: 15px;
  border-radius: 6px;
  cursor: pointer;

}

.selected_icon {
  background-color: #009ddd;
  color: white;
  padding: 20px;
  border: 0px;
  box-shadow: #888a8b 4px 3px 10px;
  text-align: center;
  font-size: 15px;
  border-radius: 6px;
  cursor: pointer;
}

.vehicle_card {
  background-color: white;
  padding: 20px;
  border: 0px;
  box-shadow: #c2c6ca 4px 3px 10px;
  text-align: center;
  font-size: 22px;
  border-radius: 6px;
  cursor: pointer;

}

.selected_vehicle {
  background-color: #009ddd;
  color: white;
  padding: 20px;
  border: 0px;
  box-shadow: #888a8b 4px 3px 10px;
  text-align: center;
  font-size: 22px;
  border-radius: 6px;
  cursor: pointer;

}

.cardplace {
  margin-right: 6%;
  margin-left: 14%;
}

.cardplace_vehicle {
  margin-left: 28%;
  margin-right: 28%;

}

.card_img {
  padding: 8px;

}

.form {
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 30px;
  text-align: center;
  margin-right: 35%;
  margin-left: 35%;
}

.button_placement {
  text-align: center;
  margin-left: 26px;
 

  Button {
    min-width: 140px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.h2tag {
  color: #009ddd;
  text-align: center;
  padding: 10px
}

@media only screen and (max-width:1250px) {

  .h2tag {
    font-size: 26px;
    color: #009ddd;
    text-align: center;
  }

  .form {
    text-align: center;
    margin-right: 10%;
    margin-left: 10%;
  }

  .button_placement {

    text-align: center;
    margin-top: 10px;
    padding: 4px;

    Button {
      min-width: 90px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .icon_card {
    min-height: 150px;
    min-width: 150px;

  }

  .selected_icon {
    min-height: 150px;
    min-width: 150px;

  }

  .cardplace {
    margin-right: 4%;
    margin-left: 10%;
  }

}

@media only screen and (max-width:600px) {

  .cardplace {
    margin-right: 6%;
    margin-left: 14%;
  }

  .icon_card {
    min-height: 60px;
    font-size: 16px;
    font-weight: bold;
  }

  .selected_icon {
    min-height: 60px;
    font-size: 16px;
    font-weight: bold;
  }

  .verticle_align {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
  }


}