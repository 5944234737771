#signInWrapper {
    .form-signin-wrapper {
        border: 0;
        margin: 5px auto;
        max-width: 400px;
        width: 100%;
        border-radius: 5px;
        font-size: 14px;
    }
}
