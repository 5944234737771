#signUpWrapper {
    .form-signup-wrapper {
        border: 0;
        margin: 5px auto;
        max-width: 500px;
        width: 100%;
        border-radius: 5px;
        font-size: 14px;
    }
}
