#contactWrapper {
    .form-contact-wrapper {
        border: 0;
        margin: 50px auto;
        max-width: 450px;
        width: 100%;
        border-radius: 5px;
        font-size: 14px;
        padding: 20px;
    }
}

.contact_us_btn {
    background-color: #009ddd;
}