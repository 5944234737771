#pricingWrapper {
  padding: 0;

  .help_text {
    color: grey;
    font-size: 10px;
  }

  .price_container_wrapper {
    padding: 25px;
    margin: 0px;

    .price_container {
      padding: 15px;
      color: #6c757d;
      font-size: 14px;
      background-color: #8080800a;
      border-radius: 5px;
      min-height: 300px;

      .price_header {
        margin-top: 15px;
        margin-bottom: 25px;
      }
    }

    .price_sign_up_btn {
      background-color: #009ddd;
      max-width: 300px;
      width: 100%;
      margin: auto;
      border: #009ddd;
      padding: 5px 10px;
      margin-top: 10px;
      color: white;
      text-decoration: none;
      display: inline-block;
      border-radius: 5px;

      &:hover {
        background-color: darken(#009ddd, 10%);
      }
    }
  }

  .sign_up_btn {
    background: #009ddde0;
    color: white;
    border-radius: 5px;
    padding: 5px 15px;
    font-weight: 400;
    font-size: 16px;
    width: 250px;
    display: block;
    margin: auto;
    cursor: pointer;

    &:hover {
      background: #009ddd;
    }
  }
}

@media only screen and (max-width: 600px) {
  #pricingWrapper {}
}