#homeHeader {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 16;
    padding: 0px 6px 4px 6px;

    .nav-item {
        margin-top: 10px;
        color: red;
    }

    .home_header_link {
        text-decoration: none;
        padding: 5px;
        margin-left: 15px;
        margin-right: 15px;
        color: #191717;
        border-bottom: 5px solid transparent;

        &:hover {
            border-bottom: 5px solid #009ddd;
        }

        &.active {
            background: #009ddd;
            color: white;
            border-radius: 5px;
            padding: 10px 15px 5px;
            font-weight: 400;
            font-size: 16px;

            &:hover {
                background: #009ddd;
                border-bottom: 5px solid transparent;
            }
        }
    }
}

@media only screen and (max-width: 850px) {
    a {
        font-size: 14px;
    }

    #homeHeader {
        padding: 8px 3px;

        .nav-item {
            margin-top: 8px;
        }

        .home_header_link {
            padding: 3px;
            margin-left: 12px;
            margin-right: 12px;

            &.active {
                background: #009ddd;
                color: white;
                border-radius: 5px;
                padding: 8px 12px 3px;
                font-size: 14px;


            }
        }
    }

}